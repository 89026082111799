import TravelerMultipleSelector from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/traveler-multiple-selector/2.2.5/index.vue';
export default {
    name: 'positionLevelChoose',
    data() {
        return {
            //------------------------
            enableAutoInit: false,
            staffIdList: [],
            staffNameList: [],
            //------------------------
            activeBtn: null,
            positionIdList: [],
            type: "",
            localEmployeeVoArr: [],
        }
    },
    props: {
        employeeVoArr: {
            type: Array,
            default: () => []
        }
    },
    components: {TravelerMultipleSelector},
    created() {
    },
    mounted() {
        this.$nextTick(()=>{
            this.$refs.travelerMultipleSelector.init_parameter.btn_ok_click_event_handler = (args) =>{
                console.log('args', args)
                let userList = args.selected_entity_list;
                this.staffNameList = [];
                // let selectedStaffIdList = [];
                for (let i = 0;i < userList.length;i++) {
                    this.staffNameList.push({name: userList[i].name, isEcho: true, id: userList[i].userId});
                }
            };
        })
    },
    activated() {
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {
        /*staffIdList(val) {
            let userList = this.$refs.travelerMultipleSelector.get_selected_entity_list();
            console.log('11222222');
            console.log(userList);
            this.staffNameList = [];
            let selectedStaffIdList = [];
            for (let i = 0;i < userList.length;i++) {
                if(val.indexOf(userList[i].userId) > -1) {
                    this.staffNameList.push({name: userList[i].name, isEcho: true, id: userList[i].userId});
                    selectedStaffIdList.push(userList[i].userId);
                }
            }
            this.localEmployeeVoArr = selectedStaffIdList;
        }*/
    },
    computed: {
        arrangePositionVo(type) {
            return function(type) {
                return this.getArrangePositionVo(type);
            };
        },
    },
    filters: {},
    methods: {
        setUserInfo(val) {
            this.staffNameList = [];
            this.staffIdList = val.userIds;
            this.$nextTick(() => {
                val.userIds.forEach((item,index) => {
                    this.staffNameList.push({name: val.userNames[index], isEcho: true, id: item});
                });
                this.localEmployeeVoArr = val.userIds;
            });
        },
        // 选择员工
        clickCascader() {
            this.$refs.travelerMultipleSelector.init({
                situation: `100`,
            });
            this.$refs.travelerMultipleSelector.show();
        },
        init(val) {
            console.log('val', val)
            /*
            * id: String 传入id
            * type: String 'edit': 编辑模式， 'add': 新增模式
            * businessType: '' 1:机票 2：火车票 3：酒店
            * */
            let type = val.type
            if (type) {
                this.type = type
            }
            let data = {}
            data.businessType = val.businessType
            if (this.type === 'edit') {
                data.id = val.id

            }
        },
        // 更改员工显示
        getArrangePositionVo(type) {
            console.log(this.staffNameList);
            return this.staffNameList.filter((item) => {
                console.log(item);
                if (type) {
                    return item.isEcho;
                } else {
                    return !item.isEcho;
                }
            });
        },
        // 删除员工
        deletePosition(val) {
            this.staffNameList = this.staffNameList.filter((num, index, arr) => {
              return num.id === val.id ? false : true;
            });
            this.staffIdList = this.staffIdList.filter((num, index, arr) => {
                return num === val.id ? false : true;
            });
            console.log(this.staffNameList);
            console.log(this.staffIdList);
        },
        // 清空选择
        emptyPosition() {
            this.staffNameList = [];
            this.localEmployeeVoArr = [];
            this.staffIdList = [];
        },
    }
}
